<template>
  <div class="container" id="yinDao">
    <div class="header"></div>    
    <bar />
    <!-- 未绑定设备 start-->
    <div v-show="hasDevice === false" class="h35"></div>
    <div v-show="hasDevice  === false" class="no-bind">
      <div class="h15"></div>
      <img @click="$router.push({name: 'deviceBind'})" src="@/accesst/index/bind.png" class="bind-img" alt="" srcset="" />
      <div class="bind-tip">您还没有绑定设备，请绑定</div>
    </div>
    <!-- 未绑定设备 end-->
    <!-- 绑定设备后无数据 start-->
    <!-- <div v-show="hasAI === false" class="h35"></div> -->
    <div v-show="hasAI === false" class="no-data">
      <div class="no-data-header">
        <div class="no-data-header-l">未获取到检测结果</div>
        <userDeviceListVue @changeForm="changeForm" v-if="deviceUsers" :list="deviceUsers" :form="deviceUserForm" />
        <div v-else>&nbsp;</div>
      </div>
      <div class="no-data-empty">
        <van-empty :description="`请用${deviceUserForm.userName || '用户1'}进行AI检查，检测完成后数据会自动同步`" />
      </div>
    </div>
    <!-- 绑定设备后无数据 end-->
    <!-- 第一次检测 start -->
    <!-- <div v-if="aiCheckList.length === 1" class="h35"></div> -->
    <div v-show="aiCheckList.length === 1" class="no-data">
      <div class="no-data-header">
        <div class="no-data-header-l">{{ checkPrompt }}</div>
        <userDeviceListVue @changeForm="changeForm" v-if="deviceUsers" :list="deviceUsers" :form="deviceUserForm" />
        <div v-else>&nbsp;</div>
      </div>
      <div v-if="aiCheckList.length === 1" class="check-list">
        <div class="check-info">
          <div class="check-info-item">
            <span>双眼： {{aiCheckList[0].eyeTwoVal}}</span>
            <img v-if="aiCheckList[0].eyeTwoComp < 0" src="@/accesst/check/down.png" alt="" srcset="" />
            <img v-if="aiCheckList[0].eyeTwoComp > 0" src="@/accesst/check/up.png" alt="" srcset="" />
            <img v-if="aiCheckList[0].eyeTwoComp == 0 || aiCheckList[0].eyeTwoComp == null" src="@/accesst/check/hr.png" alt="" srcset="" />
            {{ aiCheckList[0].eyeTwoComp == 0 || aiCheckList[0].eyeTwoComp == null ? '与平均值持平': (aiCheckList[0].eyeTwoComp > 0 ? '高于平均值'+ aiCheckList[0].eyeTwoComp + '%' : '低于平均值'+ aiCheckList[0].eyeTwoComp*-1 + '%') }}
          </div>
          <div class="check-info-line"></div>
          <div class="check-info-item">
            <span>左眼： {{aiCheckList[0].eyeLeftVal}}</span>
            <img v-if="aiCheckList[0].eyeLeftComp < 0" src="@/accesst/check/down.png" alt="" srcset="" />
            <img v-if="aiCheckList[0].eyeLeftComp > 0" src="@/accesst/check/up.png" alt="" srcset="" />
            <img v-if="aiCheckList[0].eyeLeftComp == 0 || aiCheckList[0].eyeLeftComp == null" src="@/accesst/check/hr.png" alt="" srcset="" />
            {{ aiCheckList[0].eyeLeftComp == 0 || aiCheckList[0].eyeLeftComp == null ? '与平均值持平': (aiCheckList[0].eyeLeftComp > 0 ? '高于平均值'+ aiCheckList[0].eyeLeftComp + '%' : '低于平均值'+ aiCheckList[0].eyeLeftComp*-1 + '%') }}
          </div>
          <div class="check-info-line"></div>
          <div class="check-info-item">
            <span>右眼： {{aiCheckList[0].eyeRightVal}}</span>
            <img v-if="aiCheckList[0].eyeRightComp < 0" src="@/accesst/check/down.png" alt="" srcset="" />
            <img v-if="aiCheckList[0].eyeRightComp > 0" src="@/accesst/check/up.png" alt="" srcset="" />
            <img v-if="aiCheckList[0].eyeRightComp == 0 || aiCheckList[0].eyeRightComp == null" src="@/accesst/check/hr.png" alt="" srcset="" />
            {{ aiCheckList[0].eyeRightComp == 0 || aiCheckList[0].eyeRightComp == null ? '与平均值持平': (aiCheckList[0].eyeRightComp > 0 ? '高于平均值'+ aiCheckList[0].eyeRightComp + '%' : '低于平均值'+ aiCheckList[0].eyeRightComp*-1 + '%') }}
          </div>
        </div>
        <div class="check-tip-info">
          <div class="check-tip-title">
            <template v-if="totalCheckResultComp > 0">
              当前视力高于平均水平
            </template>
            <template v-if="totalCheckResultComp < 0">
              当前视力低于平均水平
            </template>
            <template v-if="totalCheckResultComp == 0">
              当前视力与平均水平持平
            </template>
            <!-- <span v-show="totalCheckResultComp < 0" style="color: #E90000">
              约{{ totalCheckResultComp * -1 }}
            </span>
            <span v-show="totalCheckResultComp > 0" style="color: #13A663">
              约{{ totalCheckResultComp }}
            </span> -->
            <img v-show="totalCheckResultComp<0" src="@/accesst/check/xia.png" style="width: .25rem" alt="" srcset="">
            <img v-show="totalCheckResultComp>0" src="@/accesst/check/shang.png" style="width: .25rem"   alt="" srcset="">
          </div>
          <div class="check-tip-des">*检测数据仅作参考，如有眼部疾病请及时就医确诊</div>
        </div>
      </div>
      <div class="h35"></div>      
      <moreCheckE v-if="aiCheckList.length === 1" ref="firstMoreCheckE" :form="deviceUserForm" key="first-check-e" id="first-check-e" />
      <!-- <div class="h35"></div> -->
    </div>
    <!-- 第一次检测 End -->
    <!-- 多次检测 start -->
    <!-- <div v-if="aiCheckList.length > 1" class="h35"></div> -->
    <div v-show="aiCheckList.length > 1" class="no-data">
      <div class="no-data-header">
        <div class="no-data-header-l">{{ checkPrompt }}</div>      
        <userDeviceListVue @changeForm="changeForm" v-if="deviceUsers" :list="deviceUsers" :form="deviceUserForm" />
        <div v-else>&nbsp;</div>
      </div>
      <!-- <div class="h35"></div> -->
      <!--对比图标 -->
      <div class="contrast-echart">
        <contrast :_aiCheckList="aiCheckList" :tipInfo="tipInfo" :form="deviceUserForm"></contrast>
      </div>
      <div class="h35"></div>
      <moreCheckE2 v-if="aiCheckList.length > 1" ref="moreCheckE" :stack="'total'" :form="deviceUserForm" key="more-check-e" id="more-check-e"/>
      <!-- <div class="h35"></div> -->
    </div>
    <!-- 多次检测 end -->
    <!-- 快速入口 -->
    <!-- <div class="h35"></div> -->    
    <div class="h35"></div>
    <!-- 排行榜 -->
    <rank />
    <div class="h35"></div>
    <!-- 引导 -->
    <div @click="step+=1" v-preventReClick class="yi-mask" v-show="showStep">
      <div v-preventReClick v-show="step === 1" class="user-m">
        <img src="../accesst/index/manage.png"/>
        用户管理
      </div>
      <div v-preventReClick v-show="step === 1" class="yi-tip">这里可以创建更多用户</div>
      <img v-preventReClick v-show="step === 1" src="../accesst/index/tip.png" class="yi-img" />

      <div v-show="step === 2" v-preventReClick class="user-m2">
        <img src="../accesst/index/help.png"/>
        家长帮助
      </div>
      <div v-show="step === 2" v-preventReClick class="yi-tip2">需要帮助孩子在设备上进行操作时<br>可以点这里</div>
      <img v-show="step === 2" v-preventReClick src="../accesst/index/tip.png" class="yi-img2" />
    </div>
  </div>
</template>
<script>
import { isArray, groupBy, forEach, find } from 'lodash';
import bar from '@/view/components/bar.vue';
import rank from '@/view/components/rank.vue';
import userDeviceListVue from '@/view/components/userDeviceList.vue';
import indexProgressVue from './components/indexProgress.vue';
import firstCheckE from '@/view/components/firstCheckE.vue';
import moreCheckE from '@/view/components/moreCheckE.vue';
import contrast from '@/view/components/contrast.vue';
import {Empty} from 'vant'
import { getUserInfo, bindDevicePage, deviceNewUserPage, getUserAiCheck } from '@/api/h5'
import moreCheckE2 from './components/moreCheckE-2.vue';
export default {
  components: { bar, rank, userDeviceListVue, indexProgressVue, firstCheckE, contrast, moreCheckE, moreCheckE2, [Empty.name]: Empty },
  data() {
    return {
      hasDevice: null, // 是否绑定设备
      hasAI: null, // 是否有AI检测数据
      aiCheckList: [],
      checkPrompt: '',
      totalCheckResultVal: '',
      totalCheckResultComp: 0,
      deviceUserForm: {
        deviceId: '',
        deviceUserId: '',
      },
      deviceUsers:null,
      tipInfo: {},
      step: 1,
      showStep: false
    }
  },
  watch: {
    step (n, o) {
      if(n > 2) {
        this.showStep = false;
        this.$store.state.showStep = false
      }
    }
  },
  mounted() {
    this.yinDao()
    // 1. 获取当前用户绑定设备的列表 判断是否绑定过设备
    bindDevicePage({}).then(res => {
      console.log(res, 'bindDevicePage');
      if(res.total && res.list && res.list.length) {
        this.hasDevice = true;
        // 2. 查询 设备<--->使用者列表
        deviceNewUserPage({pageSize: 100}).then(dusers => {
          console.log(dusers, '设备<--new ->使用者列表')
          if(dusers && dusers.list && dusers.list.length) {            
            const deviceUsers = dusers.list;
            const curDeviceSnAndUuid = dusers.curDeviceSnAndUuid || {};
            console.log(find(deviceUsers, ['uuid', curDeviceSnAndUuid.uuid]), curDeviceSnAndUuid.uuid)
            let defaultUser = find(deviceUsers, ['uuid', curDeviceSnAndUuid.uuid]) || deviceUsers[0];
            this.deviceUserForm.deviceUserId = defaultUser.uuid;
            this.deviceUserForm.userName = defaultUser.name;
            console.log(deviceUsers, this.deviceUserForm,   '--deviceUsers---')
            this.deviceUsers = deviceUsers;
            
            this.initData()
          }                    
        })        
      } else {
        this.hasDevice = false;
      }
    })
  },
  methods: {
    yinDao() {
      console.log(this.$store.state.showStep, 'this.$store.state.showStep')
      this.$nextTick(() => {
        const div = document.getElementById('yinDao')
        console.log(div.scrollTop)
        div.scrollTop = 0
        this.showStep = this.$store.state.showStep
      })      
    },
    changeForm(form) {
      console.log('changeForm', form);
      this.deviceUserForm = {
        ...this.deviceUserForm,
        ...form
      }
      this.initData();
    },
    initData() {
      // this.$refs['firstMoreCheckE'].init(this.deviceUserForm);
      // this.$refs['moreCheckE'].init(this.deviceUserForm);
      // 3. 查询 AI检测 查询 护眼计划
      getUserAiCheck({ uuid: this.deviceUserForm.deviceUserId}).then(res => {
        console.log(res, 'AI 检测数据')
        if(res && res.aiCheckList && res.aiCheckList.length) {
          this.aiCheckList.length = 0;
          this.aiCheckList = res.aiCheckList;
          this.checkPrompt = res.checkPrompt
          this.totalCheckResultVal = res.totalCheckResultVal
          this.totalCheckResultComp = res.totalCheckResultComp || 0
          this.tipInfo = {
            checkPrompt: res.checkPrompt || '',
            totalCheckResultComp: res.totalCheckResultComp || 0,
            totalCheckResultVal: res.totalCheckResultVal || ''
          }
          this.hasAI = true;
        } else {
          this.aiCheckList.length = 0;
          this.checkPrompt = ''
          this.hasAI = false;
          this.tipInfo = {};
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  width: 100vw;
  min-height: 100vh;
  .yi-mask {
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    background: rgba(0,0,0,0.7);
    .user-m {
      position: fixed;
      left: 2.2rem;
      top: 3rem;
      // display: inline-block;
      text-align: center;
      img {
        display: block;
        margin: 0 auto .17rem;
        width: .8rem;
        height: .8rem;
      }
      font-weight: 400;
      font-size: .27rem;
      color: #FFFFFF;
    }
    .user-m2 {
      position: fixed;
      left: .36rem;
      top: 3rem;
      // display: inline-block;
      text-align: center;
      img {
        display: block;
        margin: 0 auto .17rem;
        width: .8rem;
        height: .8rem;
      }
      font-weight: 400;
      font-size: .27rem;
      color: #FFFFFF;
    }
    .yi-tip2 {
      position: fixed;
      left: 1.37rem;
      top: 5.3rem;
      width: 5.08rem;
      // height: 1.42rem;
      padding: .12rem 0;
      background: rgba(255,255,255,0.32);
      border-radius: .88rem;
      border: .02rem dotted #FFFFFF;
      font-weight: 400;
      font-size: .31rem;
      color: #FFFFFF;
      line-height: .5rem;
      text-align: center;
      backdrop-filter: blur(.10rem);
    }
    .yi-tip {
      position: fixed;
      left: 1.37rem;
      top: 5.3rem;
      width: 5.08rem;
      height: 1.02rem;
      background: rgba(255,255,255,0.32);
      border-radius: .88rem;
      border: .02rem dotted #FFFFFF;
      font-weight: 400;
      font-size: .31rem;
      color: #FFFFFF;
      line-height: 1.02rem;
      text-align: center;
      backdrop-filter: blur(.10rem);
    }
    .yi-img {
      position: fixed;
      left: 3.4rem;
      top: 3.8rem;
      width: .96rem;
      height: 1.40rem;
    }
    .yi-img2 {
      position: fixed;
      left: 1.5rem;
      top: 3.8rem;
      width: .96rem;
      height: 1.40rem;
    }
  }
  .contrast-echart {
    width: 6.9rem;
    height: 5.19rem;
    border-radius: 0 0 .12rem .12rem;
    border: .01rem solid #EEEEEE;
  }

  .check-list {
    margin: 0 auto 0;
    width: 6.92rem;
    // height: 3.81rem;
    background: #F7F8FE;
    border-radius: 0 0 .1rem .1rem;
    .check-info {
      padding: 0 .3rem;
      .check-info-item {
        height: .88rem;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: .27rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #000000;
        span {
          width: 1.85rem;
        }
        img {
          width: .31rem;
          margin: 0 .8rem 0 .2rem;
        }
      }
      .check-info-line {
        height: .02rem;
        background: #EEEEEE;
      }      
    }
    .check-tip-info {
      width: 100%;
      height: 1.31rem;
      background: url('../accesst/check/bg.png') no-repeat;
      background-size: 100% 1.31rem;
      border-radius: 0 0 .12rem .12rem;
      .check-tip-title {
        padding: .2rem 0 0 .27rem;
        font-size: .31rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #2B67D9;
      }
      .check-tip-des {
        padding: .12rem 0 0 .27rem;
        font-size: .23rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #B9C2D5;
      }
    }
  }
  /deep/ .van-empty__image {
    width: 1.5rem;
    height: auto;
  }
  /deep/ .van-empty__description {
    font-size: .23rem;
    padding: 0;
    margin-top: .3rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;    
  }
  .no-data {
    padding: 0 .29rem;
    .no-data-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: .3rem .3rem .2rem;
      background: #E2ECFF;
      border-radius: .12rem .12rem 0 0;
      .no-data-header-l {
        font-size: .25rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #000000;
      }      
    }
    .no-data-empty {
      margin: 0 auto .23rem; 
      width: 6.92rem;
      height: 3.75rem;
      background: linear-gradient(180deg, #FBFBFB 0%, #F6F6F6 100%);
      border-radius: .12rem;
    }
  }
  .header{
    margin: .23rem auto 0;
    width: 6.92rem;
    height: 2.5rem;
    background: url('../accesst/index/top-banner.png') no-repeat;
    background-size: 6.92rem 2.5rem;
  }
  .h35 {
    height: .35rem;
  }
  .h15 {
    height: .15rem;
  }
  .no-bind {
    margin: 0 auto;
    width: 6.92rem;
    height: 3.75rem;
    background: linear-gradient(180deg, #FBFBFB 0%, #F6F6F6 100%);
    border-radius: .12rem;
    .bind-img {
      display: block;
      width: 4.46rem;
      height: 2.5rem;
      margin: 0 auto;
    }

    .bind-tip {
      height: .33rem;
      font-size: .23rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: .33rem;
      text-align: center;
    }
  }
}
</style>