<template>
  <div class="week-list">
    <div class="week-value" @click="show = true">{{weeks[index]}} <van-icon size=".2rem" name="arrow-down" /></div>
    <van-popup position="bottom" title="日期" v-model="show">
      <van-picker
        show-toolbar
        :columns="weeks"        
        @cancel="show = false"
        :default-index="index"
        @confirm="change"
      />
    </van-popup>
  </div>
</template>

<script>
import moment from 'moment'
import  { range, forEach } from 'lodash';
import { Popup, Picker, Icon } from 'vant';
// 生成 52 周
// const weeks = [];
// forEach(range(52), (v, k) => {
//   if(k) {
//     weeks.push(`${moment(moment().subtract(k*7, 'days').startOf('weeks')).format('yyyy.MM.DD')}-${moment(moment().subtract(k*7, 'days').endOf('weeks')).format('yyyy.MM.DD')}`)
//   } else {
//     weeks.push(`${moment(moment().startOf('weeks')).format('yyyy.MM.DD')}-${moment(moment().endOf('weeks')).format('yyyy.MM.DD')}`)
//   }
// })
// console.log(weeks);
export default {
  components: { [Popup.name]: Popup, [Picker.name]: Picker, [Icon.name]: Icon },
  props: ['weeks', 'weekIndex'],
  data() {
    return {
      show: false,
      // weeks: [],
      index: this.weekIndex || 0   
    }
  },
  methods: {
    change(v, index) {
      console.log(v, index)
      this.show = false
      this.index = index
      this.$emit('chageData', index)
    },
    init(weeks, index) {
      // this.weeks = weeks;
      // this.index = index;
    }
  }
}
</script>
<style lang="less" scoped>
.week-list {
  .week-value {
    font-size: .23rem ;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #999999;
  }
}
</style>