<template>
  <!-- 需要设置改div的宽度和长度才能显示 -->
  <div :id="id" style="height: 100%;"></div>
</template>

<script>
// 第一种方式：哪个组件需要就在哪个组件引入
import * as echarts from "echarts";
export default {
  props: {
    id: {
      type: String
    },
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      myChart: null
    }
  },
  watch: {
    option: {
      deep: true,
      handler(n, o) {
        console.log(n,o, '-----')
        this.myChart && this.myChart.setOption(n);
      },
    },
  },
  mounted() {
    this.initEcharts();
  },
  beforeDestroy() {
    if(this.myChart) this.myChart.dispose();
  },
  methods: {
    initEcharts() {
      this.myChart = echarts.init(document.getElementById(this.id));
      // 指定图表的配置项和数据
      // 使用刚指定的配置项和数据显示图表。
      // myChart.setOption(this.option);
    },
  },
};
</script>

<style>
</style>
