<template>
  <div class="rank-container">
    <div class="rank-header">
      <div class="rank-header-title">积分排行榜</div>
      <div @click="init()" class="rank-reload"><img src="@/accesst/index/reload.png" alt="" srcset=""> 实时</div>
    </div>
    <template v-for="(item, index) in list">
        <div v-bind:key="index" v-if="index < 3" :class="`bg${index+1}`"></div>
        <div v-bind:key="`item_${index}`" class="rank-item">
          <div class="rank-png">
            <img v-if="index < 3" :src="rankImg[index]" alt="" srcset="" />
            <span v-else>&nbsp;</span>
          </div>
          <div class="rank-info" :class="{'border-bottom': index > 1}">
            <div class="rank-info-l">{{ item.name }}</div>
            <div class="rank-info-c">{{ item.total || ' ' }}</div>
            <div class="rank-info-r">{{ item.detail }}</div>
          </div>
        </div>
    </template>
    <div v-show="isLoad" class="loading-div">
      <van-loading type="spinner" />
    </div>    
  </div>
</template>

<script>
import img1 from '@/accesst/index/n-1.png'
import img2 from '@/accesst/index/n-2.png'
import img3 from '@/accesst/index/n-3.png'
import { pointList } from '@/api/h5';
import { Loading } from 'vant';
export default {
  components: {[Loading.name]: Loading},
  data() {
    return {
      rankImg: [
        img1, img2, img3
      ],
      list: [],
      isLoad: true
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.isLoad = true;
      pointList({}).then(res=>{
        this.isLoad = false;
        console.log(res, 'point list')
        this.list = res.list;
      })
    }
  }
}
</script>

<style lang="less" scoped>
.rank-container {
  padding: 0 .3rem;
  .bg3 {
    height: 2px;
    background: linear-gradient(270deg, rgba(253,242,236,0) 0%, #FDF2ED 100%);
  }
  .bg2 {
    height: 2px;
    background: linear-gradient(270deg, rgba(233,243,253,0) 0%, #E8F3FD 100%);
  }
  .bg1 {
    height: 2px;
    background: linear-gradient(270deg, rgba(253,245,207,0) 0%, #FCF4CF 100%)
  }
  .loading-div {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    height: 3rem;
  }
  .rank-item {
    display: flex;
    justify-content: space-between;
    .rank-png {
      width: .68rem;
      img {
        width: .44rem;
      }
    }
    .border-bottom {
      border-bottom:2px solid #EEE;
    }    
    .rank-info {
      display: flex;
      justify-content: space-between;
      height: .86rem;
      width: 6.25rem;
      align-items: center;
      .rank-info-l {
        width: 1.2rem;
        font-size: .27rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #000000;
        overflow: hidden;
        text-align: left;
      }
      .rank-info-c {
        width: 1rem;
        text-align: center;
        font-size: .23rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #000000;
        overflow: hidden;
      }
      .rank-info-r {
        width: 2.4rem;
        text-align: right;
        overflow: hidden;
        font-size: .23rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 400;
        color: #000000;
      }
      
    }
  }
  .rank-header {
    height: .9rem;
    line-height: .9rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .rank-header-title {
      font-size: .31rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #000000;
    }
    .rank-reload {
      font-size: .23rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #999999;
      display: flex;
      gap: .05rem;
      align-items: center;
      img {
        width: .24rem;
        height: .24rem;
      }
    }
  }
}
</style>