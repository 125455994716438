<template>
  <div class="first-check-e">
    <weekLIst class="weekLIst" />
    <echartDemo :option="option" id="first-check-e"></echartDemo>
  </div>
</template>

<script>
import echartDemo from '@/view/components/echart.vue';
import weekLIst from './weekLIst.vue';

export default {
  components: { echartDemo, weekLIst },
  data() {
    return {
      option: null
    }    
  },
  created() {

  },
  mounted() {
    this.option = {
      title: {
        show: false
      },
      tooltip: {
        trigger: 'axis'
      },
      legend: {
        data: ['左眼', '右眼', '双眼'],
        left: 0,
        itemWidth: 14,
        itemGap: 10,
        itemHeight: 14
      },
      color: ['#19B494', '#2C38FF', '#FFADE1'],
      grid: {
        left: 30,
        right: 10,
        bottom: 50,
        top: 50
      },
      // calculable: true,  
      xAxis: [
        {
          type: 'category',
          data: ['星期一', '星期二', '星期三', '星期四', '星期五', '星期六', '星期日']
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        {
          name: '左眼',
          type: 'bar',
          data: [
            2.0, 4.9, 7.0, 23.2, 25.6, 76.7, 135.6
          ],
        },
        {
          name: '右眼',
          type: 'bar',
          data: [
            2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 
          ]
        },
        {
          name: '双眼',
          type: 'bar',
          data: [
            2.6, 5.9, 9.0, 26.4, 28.7, 70.7, 175.6, 
          ]
        }
      ]
    };
  }
}
</script>

<style lang="less" scoped>
.first-check-e {
  position: relative;
  width: 6.9rem;
  height: 4.75rem;
  border-bottom: 2px solid #EEEEEE;
  .weekLIst {
    position: absolute;
    right: 0;
    z-index: 2;
    top: .08rem;
  }
}
</style>