<template>
  <div class="index-pro">
    <div class="index-pro-title">{{first ?  '今日护眼未达标' : '每天使用产品3次 共36分钟' }}</div>    
    <div class="index-pro-info">
      <span>{{ first ? '每日3次  共36分钟' : '今天护眼时长' }}</span>
      <div class="pro-div"><van-progress :show-pivot="false" :percentage="50" stroke-width="4" track-color="#EEEEEE" color="#2B67D9" /></div>
      <span>10分钟</span>
    </div>    
  </div>
</template>

<script>
import { Progress } from 'vant'
export default {
  components: {[Progress.name]: Progress},
  props: {
    first: {
      type: Boolean,
      default: true
    }
  },
  setup() {
    
  },
}
</script>

<style lang="less" scoped>
.index-pro {  
  .pro-div {
    width: 100%;
  }
  .index-pro-title {
    font-size: .27rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    color: #000000;
  }
  .index-pro-info {
    display: flex;
    justify-content: space-between;
    gap: 25px;
    align-items: center;
    margin: .12rem auto ;
    padding: 0 .12rem;
    width: 6.68rem;
    height: .65rem;
    background: #FFFFFF;
    border-radius: .08rem;
    border: .02rem solid #EEEEEE;
    font-size: .23rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #000000;
    white-space: nowrap;
  }
}
</style>