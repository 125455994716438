<template>
  <div class="first-check-e">
    <div class="sub-title">{{info.planResult}}</div>
    <div class="h27"></div>
    <div class="sub-line">
      <div v-if="info.dayCareCompareVal < 0" class="sub-line-title">
        {{ info.dayCareDetail }}
        <span classr="red">约{{info.dayCareCompareVal * -1}}%</span>
        <img class="icon-img" src="@/accesst/check/xia.png"/>
      </div>
      <div v-if="info.dayCareCompareVal > 0" class="sub-line-title">
        {{ info.dayCareDetail }}
        <span  class="green">约{{info.dayCareCompareVal}}%</span>
        <img class="icon-img" src="@/accesst/check/shang.png"/>
      </div>
      <div v-if="info.dayCareCompareVal == 0 || info.dayCareCompareVal == null" class="sub-line-title">
        {{ info.dayCareDetail }}
        <!-- <span color="red">约{{info.dayCareCompareVal}}%</span> -->
        <!-- <img class="icon-img" src="@/accesst/check/shang.png"/> -->
      </div>
      <weekLIst v-show="weeks.length" :weeks="weeks" :weekIndex="weekIndex" @chageData="initOption" ref="week" class="weekLIst" />
      <div v-show="weeks.length===0">&nbsp;</div>
    </div>
    
    <echartDemo v-show="weeks.length" style="width: 6.9rem; height: 4.75rem;" :option="option" :id="id"></echartDemo>
    <van-empty v-show="weeks.length===0" description="暂无记录" />
  </div>
</template>

<script>
import echartDemo from '@/view/components/echart.vue';
import { Empty } from 'vant';
import weekLIst from './weekLIst.vue';
import { getWeekPlanDetail } from '@/api/h5';
import { forEach, isArray, range } from 'lodash'
import moment from 'moment'
// 生成 52 周
const weeks = [];
forEach(range(52), (v, k) => {
  if(k) {
    weeks.push(`${moment(moment().subtract(k*7, 'days').startOf('weeks')).format('MM-DD')}至${moment(moment().subtract(k*7, 'days').endOf('weeks')).format('MM-DD')}`)
  } else {
    weeks.push(`${moment(moment().startOf('weeks')).format('MM-DD')}至${moment(moment().endOf('weeks')).format('MM-DD')}`)
  }
})
console.log(weeks, '--++++--');

function extractNumbers(str) {
  return str.match(/\d+/g);
}

export default {
  components: { echartDemo, weekLIst, [Empty.name]: Empty },
  name: 'moreCheckE2',
  props:['stack', 'id', 'form'],
  data() {
    return {
      option: null,
      weeks,
      weekIndex: 0,
      leftData: [],
      rightData: [],
      shuangData: [],
      times: [],
      info: {},
      yMax: 90
    }
  },
  watch: {
    form (n) {
      console.log(n, 'form 2')
      this.init(n)
    }
  },
  mounted() {
    this.init(this.form)
  },
  methods: {
    init(form) {
      setTimeout(() => {
        this._initData(form)
      }, 10);
    },
    _initData(form) {
      console.log(form, '---form--')
      const params = {
        pageSize: 52,
        // deviceId: form.deviceId,
        uuid: form.deviceUserId
      }
      
      getWeekPlanDetail(params).then(res => {
        console.log(res, '护眼计划2')
        this.info = {...res};
        console.log(this.info, '---')
        this.times = [];
        // 提取标题中 的数字
        if(res.planResult) {
          const nums = extractNumbers(res.planResult);
          if(isArray(nums) && nums[0]) this.yMax = parseInt(nums[0] * 1.5);
        }

        forEach(res.weekPlan, v => {        
          const sunVal = v.sunVal || 0;
          const monVal = v.monVal || 0;
          const tuesVal = v.tuesVal || 0;
          const wedVal = v.wedVal || 0;;
          const thurVal = v.thurVal || 0;
          const friVal = v.friVal || 0;
          const satVal = v.satVal || 0;
          console.log(sunVal, '----')
          this.times.push([sunVal, monVal, tuesVal, wedVal, thurVal, friVal, satVal]);          
        })

        this.weekIndex = 0;
        console.log(this.times, 'this.times')
        this.$nextTick(() => {
          this.initOption(this.weekIndex);

          // console.log(this.$refs, 'this.$refs[]')
          // this.$refs['week'].init(this.weeks, 0);
        })      
      })
    },
    initOption(weekIndex) {
      console.log(weekIndex)      
      this.option = {
        title: {
          show: false
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          // data: ['左眼', '右眼', '双眼'],
          left: 0,
          itemWidth: 14,
          itemGap: 10,
          itemHeight: 14,
          show: false
        },
        // color: ['#19B494', '#2C38FF', '#FFADE1'],
        color: ['#2C38FF'],
        grid: {
          left: 30,
          right:20,
          bottom: 50,
          top: 30
        },
        // calculable: true,  
        xAxis: [
          {
            type: 'category',
            data: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
          }
        ],
        yAxis: [
          {
            type: 'value',
            max: this.yMax
          }
        ],
        series: [
          {
            name: '时长',
            // stack: this.stack,
            type: 'bar',
            data: this.times[weekIndex] || [0, 0, 0, 0, 0, 0, 0],
            markLine: {
              data: [{ type: 'average', name: '平均值' }],
              color: '#999'
            }
          },
          // {
          //   name: '时长',
          //   stack: this.stack,
          //   type: 'bar',
          //   data: this.leftData[weekIndex],
          // },
        ]
      };
    }
  }
}
</script>

<style lang="less" scoped>
.first-check-e {
  position: relative;
  width: 6.9rem;
  // height: 4.75rem;
  border-bottom: 2px solid #EEEEEE;
  .red {
    color: #E90000 !important;
  }
  .green {
    color: #13A663
  }
  .icon-img {
    width: .17rem;
  }
  .h27 {
    height: .27rem;
  }
  .sub-title {
    font-family: PingFangSC, PingFang SC;
    font-weight: 600;
    font-size: .27rem;
    color: #000000;    
    text-align: left;
  }

  .sub-line {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sub-line-title {
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      font-size: .23rem;
      color: #666666;
      text-align: left;
      font-style: normal;
      white-space: nowrap;
    }
  }
  // .weekLIst {
  //   position: absolute;
  //   right: 0;
  //   z-index: 2;
  //   top: .08rem;
  // }
}
</style>