<template>
  <!-- 对比区域 -->
  <div class="contrast">
    <div v-if="aiCheckList && aiCheckList.length" class="contrast-head">
      <div style="white-space: nowrap;">
        <template v-if="tipInfo.totalCheckResultComp > 0">
          当前对比视力逐步上升
          <img src="@/accesst/check/shang.png" alt="" srcset="">
        </template>
        <template v-if="tipInfo.totalCheckResultComp < 0">
          当前对比视力出现下降趋势
          <img src="@/accesst/check/xia.png" alt="" srcset="">
        </template>
        <template v-if="tipInfo.totalCheckResultComp == 0">
          当前在误差波动范围内
          <!-- <span class="color-down">约{{tipInfo.totalCheckResultComp }}</span> -->
        </template>
      </div>
      <div v-if="list && list.length" @click="show=true" class="contrast-head-right">
        <div>{{ list[index]  }}<van-icon size=".2rem" name="arrow-down" /></div>
        <div class="c-999">至</div>
        <div>{{ list[index2] }}<van-icon size=".2rem" name="arrow-down" /></div>
      </div>
      <div v-else> &nbsp;</div>
    </div>
    <div class="contrast-echart">
      <echartDemo style="width: 6.9rem; height: 3.95rem;" :option="option" id="contrast-echart"></echartDemo>
    </div>
    <div class="contrast-echart-tip">
      *检测数据仅作参考，如有眼部疾病请及时就医
      <span @click="href()">详情&nbsp;<van-icon class="item-end-btn-arrow" name="arrow" /></span>
    </div>
    <van-popup position="bottom" title="日期" v-model="show">
      <van-picker show-toolbar :columns="columns" @cancel="show = false" @confirm="change" />
    </van-popup>

    <!-- <van-popup position="bottom" title="日期" v-model="show2">
      <van-picker show-toolbar :columns="list" @cancel="show2 = false" :default-index="index2" @confirm="change2" />
    </van-popup> -->
  </div>
</template>
<script>
import { Popup, Picker, Icon } from 'vant';
import echartDemo from '@/view/components/echart.vue';
import { forEach, slice } from 'lodash';
import * as echarts from 'echarts';
import moment from 'moment';
export default {
  components: { [Popup.name]: Popup, [Picker.name]: Picker, [Icon.name]: Icon, echartDemo },
  props: ['_aiCheckList', 'tipInfo', 'form'],
  data() {
    return {
      list: [],
      index: 0,
      index2: 0,
      show: false,
      show2: false,
      option: null,
      aiCheckList: [],
      columns: [],
      data: []
    }
  },
  watch : {
    _aiCheckList: {
      immediate:true,
      deep:true,
      handler(n){
        console.log(n, '_aiCheckList');
        this.initOption(n);
      }
    }
  },
  mounted() {    
  }, 
  methods: {
    href() {
      this.$router.push({path: '/checkList', query: {...this.form}})
    },
    initOption(aiCheckList) {
      console.log(aiCheckList, 'aiCheckList');
      this.aiCheckList = aiCheckList      
      const data = [];
      const list = [];
      const options = [];
      forEach(aiCheckList, (v, k) => {
        const t = moment(v.checkDatetime).format('MM-DD HH:mm');
        list.push(k === 0 ? '首次' : (k=== aiCheckList.length-1 ? '当前' : `第${k+1}次`));
        options.push(k === 0 ? `${t}(首次)` : (k=== aiCheckList.length-1 ? `${t}(当前)` : t));
        data.push(v.eyeTwoVal);
      })
      this.list = list;
      this.index = 0;
      this.index2 = options.length - 1;
      this.data = data;
      this.columns = [
        { values: options, defaultIndex: this.index },
        { values: options, defaultIndex: this.index2 }
      ];

      this.initEchart();      
    },
    initEchart() {
      const xData = slice(this.list, this.index, this.index2 + 1);
      const yData = slice(this.data, this.index, this.index2 + 1);
      this.option = {
        title: {
          show: false
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          show: false
        },
        color: ['#2B67D9'],
        grid: {
          left: 60,
          right: 20,
          bottom: 30,
          top: 30
        },
        // calculable: true,  
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            data: xData,
            axisLabel: {
              show: xData.length > 4 ? false : true
            }  
          }
        ],
        yAxis: [
          {
            type: 'value'
          }
        ],
        series: [
          {
            type: 'line',
            data: yData,
            areaStyle: {
              origin: "start",//向最小值方向渐变，y轴有负值要写
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgba(43, 103, 217, 1)",
                },
                {
                  offset: 1,
                  color: "rgba(43,103,217,0) ",
                }
              ])
            }
          }
        ]
      };
    },
    change(v, indexs) {
      console.log(v, indexs)
      const index = indexs[0];
      const index2 = indexs[1];
      if(index > index2) {
        this.$toast('请选择正确的对比区间')
      } else {
        this.index = index;
        this.index2 = index2;
        this.show = false
        this.initEchart();
      }
    },
  }
}
</script>

<style lang="less" scoped>
.contrast {
  width: 100%;
  height: 100%;

  .contrast-echart {
    width: 100%;
    height: 3.95rem;
  }

  .contrast-echart-tip {
    padding: 0 .25rem .25rem .25rem;
    font-size: .23rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #B9C2D5;
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-size: .23rem;
      color: #B9C2D5;
    }
  }

  .contrast-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: .25rem .11rem 0;
    font-size: .22rem;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #000000;

    img {
      margin: 0 .0rem;
      width: .2rem;
      vertical-align: sub;
    }

    .color-up {
      font-weight: 500;
      color: #13A663
    }

    .color-down {
      color: #E90000
    }

    .contrast-head-right {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 2.62rem;
      height: .5rem;
      background: #F7F8FA;
      border-radius: .04rem;
      font-size: .23rem;
      font-family: PingFangSC, PingFang SC;
      font-weight: 400;
      color: #CCCCCC;

      .c-999 {
        color: #999;
      }
    }
  }
}
</style>